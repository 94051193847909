import React, { Component, Suspense, lazy } from "react";
import "babel-polyfill";
import { Router, Route, Redirect, Switch} from "react-router-dom";
import { AuthenticationContextProvider, ProtectedRoute } from './components/authentication'
import { createBrowserHistory } from 'history';

const UpgradeEng = lazy(() => import('./pages/UserManagement/UpgradeEng.js'));
const Upgrade1 = lazy(() => import('./pages/UserManagement/Upgrade1.js'));
const Subscription = lazy(() => import('./pages/UserManagement/Subscription.js'));
const ResetPasswordComplete = lazy(() => import('./pages/UserManagement/ResetPasswordComplete.js'));
const ResetPassword = lazy(() => import('./pages/UserManagement/ResetPassword.js'));
const Register = lazy(() => import('./pages/UserManagement/Register.js'));
const Login = lazy(() => import('./pages/UserManagement/Login.js'));
const ConfirmEmail = lazy(() => import('./pages/UserManagement/ConfirmEmail.js'));
const ChangePassword = lazy(() => import('./pages/UserManagement/ChangePassword.js'));
const ChangeEmail = lazy(() => import('./pages/UserManagement/ChangeEmail.js'));
const ChangeAccess = lazy(() => import('./pages/UserManagement/ChangeAccess.js'));
const HomeS = lazy(() => import('./pages/home/HomeS.js'));
const CancionesS = lazy(() => import('./pages/Canciones/CancionesS.js'));
const LyricPageS = lazy(() => import('./pages/Canciones/LyricPageS.js'));
const BedtimeS = lazy(() => import('./pages/Cuentos/BedtimeS.js'));
const CuentosClasicosS = lazy(() => import('./pages/Cuentos/CuentosClasicosS.js'));
const CuentosInglesS = lazy(() => import('./pages/Cuentos/CuentosInglesS.js'));
const CuentosMundoS = lazy(() => import('./pages/Cuentos/CuentosMundoS.js'));
const CuentosPrincesasS = lazy(() => import('./pages/Cuentos/CuentosPrincesasS.js'));
const CuentosAnimalesS = lazy(() => import('./pages/Cuentos/CuentosAnimalesS.js'));
const CuentosLatinamericanosS = lazy(() => import('./pages/Cuentos/CuentosLatinamericanoS.js'));
const TerrorS = lazy(() => import('./pages/Cuentos/TerrorS.js'));
const CuentosLandingS = lazy(() => import('./pages/Cuentos/CuentosLandingS.js'));
const FablesS = lazy(() => import('./pages/Cuentos/FablesS.js'));
const GrimmS = lazy(() => import('./pages/Cuentos/GrimmS.js'));
const LegendsS = lazy(() => import('./pages/Cuentos/LegendsS.js'));
const OriginalStoriesS = lazy(() => import('./pages/Cuentos/OriginalStoriesS.js'));
const PoemsS = lazy(() => import('./pages/Cuentos/PoemsS.js'));
const AlphabetEnglishS = lazy(() => import('./pages/English/AlphabetEnglishS.js'));
const ColorsEnglishS = lazy(() => import('./pages/English/ColorsEnglishS.js'));
const EnglishGamesS = lazy(() => import('./pages/English/EnglishGamesS.js'));
const FeelingsEnglishS = lazy(() => import('./pages/English/FeelingsEnglishS.js'));
const NumbersEnglishS = lazy(() => import('./pages/English/NumbersEnglishS.js'));
const ColorGamesS = lazy(() => import('./pages/JuegosInfantiles/ColorGamesS.js'));
const JuegosInfantilesS = lazy(() => import('./pages/JuegosInfantiles/JuegosInfantilesLandingS.js'));
const MemoryGamesS = lazy(() => import('./pages/JuegosInfantiles/MemoryGamesS.js'));
const MentalGamesS = lazy(() => import('./pages/JuegosInfantiles/MentalGamesS.js'));
const PuzzlesS = lazy(() => import('./pages/JuegosInfantiles/PuzzlesS.js'));
const FutbolS = lazy(() => import('./pages/JuegosInfantiles/FutbolS.js'));
const JuegosAnimalesS = lazy(() => import('./pages/JuegosInfantiles/JuegosAnimalesS.js'));
const NavidadGamesS = lazy(() => import('./pages/JuegosInfantiles/NavidadS.js'));
const AlphabetGamesS = lazy(() => import('./pages/Language/AlphabetGamesS.js'));
const VowelGamesS  = lazy(() => import('./pages/Language/VowelGamesS.js'));
const LenguajeS = lazy(() => import('./pages/Language/LenguajeS.js'));
const CuriososS = lazy(() => import('./pages/Levels/CuriososS.js'));
const ExploradoresS = lazy(() => import('./pages/Levels/ExploradoresS.js'));
const IngeniososS = lazy(() => import('./pages/Levels/IngeniososS.js'));
const AdditionGamesS = lazy(() => import('./pages/Math/AdditionGamesSS.js'));
const MathGamesLandingS = lazy(() => import('./pages/Math/MathGamesLandingS.js'));
const MultiplicationS = lazy(() => import('./pages/Math/Multiplication/LandingS.js'));
const DivisionS = lazy(() => import('./pages/Math/DivisionS.js'));
const NumerosRomanosS = lazy(() => import('./pages/Math/RomanNumeralsS.js'));
const Tabla1S = lazy(() => import('./pages/Math/Multiplication/Tabla1S.js'));
const Tabla2S = lazy(() => import('./pages/Math/Multiplication/Tabla2S.js'));
const Tabla3S = lazy(() => import('./pages/Math/Multiplication/Tabla3S.js'));
const Tabla4S = lazy(() => import('./pages/Math/Multiplication/Tabla4S.js'));
const Tabla5S = lazy(() => import('./pages/Math/Multiplication/Tabla5S.js'));
const Tabla6S = lazy(() => import('./pages/Math/Multiplication/Tabla6S.js'));
const Tabla7S = lazy(() => import('./pages/Math/Multiplication/Tabla7S.js'));
const Tabla8S = lazy(() => import('./pages/Math/Multiplication/Tabla8S.js'));
const Tabla9S = lazy(() => import('./pages/Math/Multiplication/Tabla9S.js'));
const Tabla10S = lazy(() => import('./pages/Math/Multiplication/Tabla10S.js'));
const MultiplicationPrintablesS = lazy(() => import('./pages/Math/Multiplication/PrintablesS.js'));
const NumberGamesS = lazy(() => import('./pages/Math/NumberGamesSS.js'));
const FigurasGeometricasS = lazy(() => import('./pages/Math/FigurasGeometricasS.js'));
const SubtractionGamesS = lazy(() => import('./pages/Math/SubtractionGamesS.js'));
const FAQS = lazy(() => import('./pages/termsAndConditions/FAQS.js'));
const FAQEngS = lazy(() => import('./pages/termsAndConditions/FAQEngS.js'));
const FavoritesInstructionsS = lazy(() => import('./pages/termsAndConditions/FavoritesInstructionsS.js'));
const LinkPageS = lazy(() => import('./pages/linkPage/LinkPageS.js'));
const PrivacyPolicyEngS = lazy(() => import('./pages/termsAndConditions/PrivacyPolicyEngS.js'));
const PrivacyPolicyS = lazy(() => import('./pages/termsAndConditions/PrivacyPolicyS.js'));
const TermsPageS = lazy(() => import('./pages/termsAndConditions/TermsPageS.js'));
const TermsPageEngS = lazy(() => import('./pages/termsAndConditions/TermsPageEngS.js'));
const PaymentTermsS = lazy(() => import('./pages/termsAndConditions/PaymentTermsS.js'));
const PaymentTermsEngS = lazy(() => import('./pages/termsAndConditions/PaymentTermsEngS.js'));
const GamePageS = lazy(() => import('./pages/GamePage/GamePageS1.js'));
const SubFolderGamePageS = lazy(() => import('./pages/GamePage/SubFolderGamePageS1.js'));
const AprenderGamePageS = lazy(() => import('./pages/GamePage/AprenderGamePageS1.js'));
const SongPageS = lazy(() => import('./pages/SongPage/SongPageS1.js'));
const StoryPageS = lazy(() => import('./pages/StoryPage/StoryPageS1.js'));
const ShortStoryPageS = lazy(() => import('./pages/StoryPage/ShortStoryPageS1.js'));
const PoemPageS = lazy(() => import('./pages/StoryPage/PoemPageS1.js'));
const StoryGamePageS = lazy(() => import('./pages/StoryPage/StoryGamePageS1.js'));
const ScienceGameStoryS = lazy(() => import('./pages/StoryPage/ScienceGameStoryS1.js'));
const MethodologyS = lazy(() => import('./pages/Methodology/MethodologyS.js'));
const SliderBookS = lazy(() => import('./pages/SliderBook/SliderBookS1.js'));
const RefranesS = lazy(() => import('./pages/Refranes/RefranesS.js'));
const RiddlesLandingS = lazy(() => import('./pages/Riddles/RiddlesLandingS.js'));
const AdivinanzasAnimalesS = lazy(() => import('./pages/Riddles/AdivinanzasAnimalesS.js'));
const AdivinanzasProfesionesS = lazy(() => import('./pages/Riddles/AdivinanzasProfesionesS.js'));
const AdivinanzasDificilesS = lazy(() => import('./pages/Riddles/AdivinanzasDificilesS.js'));
const AdivinanzasFrutasS = lazy(() => import('./pages/Riddles/AdivinanzasFrutasS.js'));
const AdivinanzasInglesS = lazy(() => import('./pages/Riddles/AdivinanzasInglesS.js'));
const AdivinanzasMathS = lazy(() => import('./pages/Riddles/AdivinanzasMathS.js'));
const AdivinanzasTransporteS = lazy(() => import('./pages/Riddles/AdivinanzasTransporteS.js'));
const JokesLandingS = lazy(() => import('./pages/Jokes/JokesLandingS.js'));
const PepitoS = lazy(() => import('./pages/Jokes/PepitoS.js'));
const AnimalJokesS = lazy(() => import('./pages/Jokes/AnimalJokesS.js'));
const RhymesS = lazy(() => import('./pages/Rhymes/RhymesS.js'));
const TongueTwistersLandingS = lazy(() => import('./pages/TongueTwisters/TongueTwistersLandingS.js'));
const TrabalenguasCortosS = lazy(() => import('./pages/TongueTwisters/TrabalenguasCortosS.js'));
const TrabalenguasLargosS = lazy(() => import('./pages/TongueTwisters/TrabalenguasLargosS.js'));
const TrabalenguasInglesS = lazy(() => import('./pages/TongueTwisters/TrabalenguasInglesS.js'));
const DibujosLandingS = lazy(() => import('./pages/Dibujos/DibujosLandingS.js'));
const DibujosAnimalesS = lazy(() => import('./pages/Dibujos/DibujosAnimalesS.js'));
const DibujosDivertidosS = lazy(() => import('./pages/Dibujos/DibujosDivertidosS.js'));
const DibujosABCS = lazy(() => import('./pages/Dibujos/DibujosABCS.js'));
const Dibujos123S = lazy(() => import('./pages/Dibujos/Dibujos123S.js'));
const PrintablePuzzlesS = lazy(() => import('./pages/Dibujos/PrintablePuzzlesS.js'));
const MandalasS = lazy(() => import('./pages/Dibujos/MandalasS.js'));
const DiferenciasS = lazy(() => import('./pages/Dibujos/DiferenciasS.js'));
const DrawS = lazy(() => import('./pages/Dibujos/DrawS.js'));
const LaberintosS = lazy(() => import('./pages/Dibujos/LaberintosS.js'));
const HalloweenS = lazy(() => import('./pages/Dibujos/HalloweenS.js'));
const NavidadS = lazy(() => import('./pages/Dibujos/NavidadS.js'));
const ColoringPageS = lazy(() => import('./pages/Dibujos/ColoringPageS.js'));
const ColoringPage2S = lazy(() => import('./pages/Dibujos/ColoringPage2S.js'));
const ScienceLandingS = lazy(() => import('./pages/Science/ScienceLandingSS.js'));
const ExperimentsLandingS = lazy(() => import('./pages/Science/ExperimentsLandingSS.js'));
const TechnologyS = lazy(() => import('./pages/Science/TechnologySS.js'));
const ExperimentsS = lazy(() => import('./pages/Science/ExperimentsS1.js'));
const NewAnimalLandingS = lazy(() => import('./pages/Science/NewAnimalLandingS.js'));
const MamiferosS = lazy(() => import('./pages/Science/AnimalSubLanding/MammalsS.js'));
const ReptilesS = lazy(() => import('./pages/Science/AnimalSubLanding/ReptilesS.js'));
const BirdsS = lazy(() => import('./pages/Science/AnimalSubLanding/BirdsS.js'));
const AnfibiosS = lazy(() => import('./pages/Science/AnimalSubLanding/AnfibiosS.js'));
const FishS = lazy(() => import('./pages/Science/AnimalSubLanding/FishS.js'));
const InvertebratesS = lazy(() => import('./pages/Science/AnimalSubLanding/InvertebratesS.js'));
const NewAnimalArticlesS = lazy(() => import('./pages/Science/NewAnimalArticlesS.js'));
const AnimalBlogArticleS = lazy(() => import('./pages/Science/AnimalBlogArticleS.js'));
const ScienceArticlesS = lazy(() => import('./pages/Science/ScienceArticlesS1.js'));
const CurriculumS = lazy(() => import('./pages/Curriculum/CurriculumS.js'));
const HistoriaS = lazy(() => import('./pages/Historia/HistoriaS.js'));
const SocialMissionS = lazy(() => import('./pages/SocialMission/SocialMissionS.js'));
const FrasesEducativasS = lazy(() => import('./pages/frasesEducativas/FrasesEducativasS.js'));
const RecursosS = lazy(() => import('./pages/Recursos/recursosS.js'));
const RecursosArticlesS = lazy(() => import('./pages/Recursos/RecursoArticleS.js'));
const BlogArticleS = lazy(() => import('./pages/Recursos/BlogArticleS.js'));
const NotFound = lazy(() => import('./pages/notFound/NotFound.js'));
const Favoritos = lazy(() => import('./pages/Favoritos/Favoritos.js'));

const history = createBrowserHistory();

class App extends Component {
	constructor(props) {
        super(props)
        this.state = { stripePromise: null }
    }

	authenticationContext = React.createRef()

	onAuthenticated = ({ tokenExpiresOn }) => {
		setTimeout(this.authenticationContext.current.refresh, 
			tokenExpiresOn.getTime() - Date.now() - 5000);
	}

	onUnauthorized = () => {
		history.push('/login', {
			state: {
				from: 'unauthorized'
			}
		})
	}

  	render() {
		return (
			<AuthenticationContextProvider 
				ref={this.authenticationContext}
				onAuthenticated={this.onAuthenticated}
				onUnauthorized={this.onUnauthorized}
			>
          		{this.renderRoutes()}
      		</AuthenticationContextProvider>
    	)
  	}


	renderRoutes() {
		return (
			<Router history={history}>
				<Suspense fallback={<div>Loading...</div>}>
					<Switch>
						<Route exact path="/" component={lazy(() => import('./pages/home/HomeF.js'))} />
						<Route exact path="/register123" component={Register} />
						<Route exact path="/register/confirm/:token" component={ConfirmEmail} />
						<ProtectedRoute exact path="/plus/change-access" component={ChangeAccess}/>
						<ProtectedRoute exact path="/plus/change-email" component={ChangeEmail} />
						<ProtectedRoute exact path="/plus/change-password" component={ChangePassword} />
						<ProtectedRoute exact path="/plus/subscription" component={Subscription} />
						<Route exact path="/reset-password" component={ResetPassword} />
						<Route exact path="/login/forgot/:token" component={ResetPasswordComplete}/>
						<Route exact path="/login" component={Login} />
						<Route exact path="/subscribe" component={Upgrade1} />
						<Route exact path="/subscribe-english" component={UpgradeEng} />
						<Route exact path="/preguntas-frecuentes" component={lazy(() => import('./pages/termsAndConditions/FAQ.js'))} />
						<Route exact path="/faq" component={lazy(() => import('./pages/termsAndConditions/FAQEng.js'))} />
						<Route exact path="/como-agregar-favoritos" component={lazy(() => import('./pages/termsAndConditions/FavoritesInstructions.js'))} />
						<Route exact path="/enlazanos" component={lazy(() => import('./pages/linkPage/LinkPage.js'))} />
						<Route exact path="/politica-de-privacidad" component={lazy(() => import('./pages/termsAndConditions/PrivacyPolicy.js'))} />
						<Route exact path="/privacy-policy" component={lazy(() => import('./pages/termsAndConditions/PrivacyPolicyEng.js'))} />
						<Route exact path="/terminos-y-condiciones" component={lazy(() => import('./pages/termsAndConditions/TermsPage.js'))} />
						<Route exact path="/terms-of-use" component={lazy(() => import('./pages/termsAndConditions/TermsPageEng.js'))} />
						<Route exact path="/terminos-de-pago" component={lazy(() => import('./pages/termsAndConditions/PaymentTerms.js'))} />
						<Route exact path="/payment-terms" component={lazy(() => import('./pages/termsAndConditions/PaymentTermsEng.js'))} />
						<Route exact path="/canciones-infantiles" component={lazy(() => import('./pages/Canciones/CancionesLandingF.js'))} />
						<Route exact path="/canciones-infantiles/:id" component={lazy(() => import('./pages/SongPage/SongPageF.js'))} />
						<Route exact path="/letras-imprimibles/:id" component={lazy(() => import('./pages/Canciones/LyricPageF.js'))} />
						<Route exact path="/cuentos-para-dormir" component={lazy(() => import('./pages/Cuentos/BedtimeStoriesF.js'))} />
						<Route exact path="/cuentos-para-dormir/:id" component={lazy(() => import('./pages/StoryPage/StoryPageF.js'))} />
						<Route exact path="/cuentos-para-dormir/cuentos-ilustrados/:id" component={lazy(() => import('./pages/SliderBook/SliderBookF.js'))} />
						<Route exact path="/cuentos-clasicos-infantiles" component={lazy(() => import('./pages/Cuentos/CuentosClasicosF.js'))} />
						<Route exact path="/cuentos-clasicos-infantiles/:id" component={lazy(() => import('./pages/StoryPage/StoryPageF.js'))} />
						<Route exact path="/cuentos-clasicos-infantiles/arbol-abc/:id" component={lazy(() => import('./pages/StoryPage/StoryGamePageF.js'))} />
						<Route exact path="/cuentos-infantiles-cortos" component={lazy(() => import('./pages/Cuentos/CuentosLandingF.js'))} />
						<Route exact path="/fabulas-para-niños" component={lazy(() => import('./pages/Cuentos/FablesF.js'))} />
						<Route exact path="/fabulas-para-niños/:id" component={lazy(() => import('./pages/StoryPage/ShortStoryPageF.js'))} />
						<Route exact path="/cuentos-en-ingles" component={lazy(() => import('./pages/Cuentos/CuentosInglesF.js'))}  />
						<Route exact path="/cuentos-en-ingles/clasicos/:id" component={lazy(() => import('./pages/StoryPage/ShortStoryPageF.js'))} />
						<Route exact path="/cuentos-en-ingles/cuentos-ilustrados/:id" component={lazy(() => import('./pages/SliderBook/SliderBookF.js'))} />
						<Route exact path="/cuentos-hermanos-grimm" component={lazy(() => import('./pages/Cuentos/GrimmF.js'))} />
						<Route exact path="/cuentos-hermanos-grimm/:id" component={lazy(() => import('./pages/StoryPage/StoryPageF.js'))} />
						<Route exact path="/cuentos-de-princesas" component={lazy(() => import('./pages/Cuentos/CuentosPrincesasF.js'))}  />
						<Route exact path="/cuentos-de-princesas/:id" component={lazy(() => import('./pages/StoryPage/StoryPageF.js'))} />
						<Route exact path="/cuentos-de-animales" component={lazy(() => import('./pages/Cuentos/CuentosAnimalesF.js'))} />
						<Route exact path="/cuentos-latinoamericanos" component={lazy(() => import('./pages/Cuentos/CuentosLatinamericanoF.js'))} />
						<Route exact path="/cuentos-latinoamericanos/:id" component={lazy(() => import('./pages/StoryPage/StoryPageF.js'))} />
						<Route exact path="/cuentos-del-mundo" component={lazy(() => import('./pages/Cuentos/CuentosMundoF.js'))} />
						<Route exact path="/cuentos-del-mundo/:id" component={lazy(() => import('./pages/StoryPage/StoryPageF.js'))} />
						<Route exact path="/cuentos-de-terror-cortos" component={lazy(() => import('./pages/Cuentos/TerrorF.js'))} />
						<Route exact path="/cuentos-de-terror-cortos/:id" component={lazy(() => import('./pages/StoryPage/ShortStoryPageF.js'))} />
						<Route exact path="/mitos-y-leyendas-para-niños" component={lazy(() => import('./pages/Cuentos/LegendsF.js'))} />
						<Route exact path="/mitos-y-leyendas-para-niños/:id" component={lazy(() => import('./pages/StoryPage/ShortStoryPageF.js'))} />
						<Route exact path="/cuentos-inventados" component={lazy(() => import('./pages/Cuentos/OriginalStoriesF.js'))} />
						<Route exact path="/cuentos-inventados/:id" component={lazy(() => import('./pages/StoryPage/StoryPageF.js'))} />
						<Route exact path="/cuentos-inventados/cuentos-ilustrados/:id" component={lazy(() => import('./pages/SliderBook/SliderBookF.js'))} />
						<Route exact path="/cuentos-inventados/arbol-abc/:id" component={lazy(() => import('./pages/StoryPage/StoryGamePageF.js'))} />
						<Route exact path="/poemas-para-niños" component={lazy(() => import('./pages/Cuentos/PoemsF.js'))} />
						<Route exact path="/poemas-para-niños/:id" component={lazy(() => import('./pages/StoryPage/PoemPageF.js'))} />
						<Route exact path="/abecedario-en-ingles" component={lazy(() => import('./pages/English/AlphabetEnglishF.js'))}  />
						<Route exact path="/abecedario-en-ingles/:id" component={lazy(() => import('./pages/GamePage/GamePageF.js'))} />
						<Route exact path="/colores-en-ingles" component={lazy(() => import('./pages/English/ColorsEnglishF.js'))} />
						<Route exact path="/colores-en-ingles/:id" component={lazy(() => import('./pages/GamePage/GamePageF.js'))} />
						<Route exact path="/juegos-de-ingles" component={lazy(() => import('./pages/English/EnglishGamesF.js'))}  />
						<Route exact path="/vocabulario-en-ingles" component={lazy(() => import('./pages/English/FeelingsEnglishF.js'))} />
						<Route exact path="/vocabulario-en-ingles/:id" component={lazy(() => import('./pages/GamePage/GamePageF.js'))} />
						<Route exact path="/numeros-en-ingles" component={lazy(() => import('./pages/English/NumbersEnglishF.js'))} />
						<Route exact path="/numeros-en-ingles/:id" component={lazy(() => import('./pages/GamePage/GamePageF.js'))} />
						<Route exact path="/juegos-de-colores" component={lazy(() => import('./pages/JuegosInfantiles/ColorGamesF.js'))} /> 
						<Route exact path="/juegos-de-colores/:id" component={lazy(() => import('./pages/GamePage/GamePageF.js'))} /> 
						<Route exact path="/juegos-infantiles" component={lazy(() => import('./pages/JuegosInfantiles/JuegosInfantilesLandingF.js'))} /> 
						<Route exact path="/juegos-de-memoria" component={lazy(() => import('./pages/JuegosInfantiles/MemoryGamesF.js'))} /> 
						<Route exact path="/juegos-de-memoria/:id" component={lazy(() => import('./pages/GamePage/GamePageF.js'))} /> 
						<Route exact path="/juegos-de-estrategia" component={lazy(() => import('./pages/JuegosInfantiles/MentalGamesF.js'))} /> 
						<Route exact path="/juegos-de-estrategia/:id" component={lazy(() => import('./pages/GamePage/GamePageF.js'))} />  
						<Route exact path="/juegos-de-futbol" component={lazy(() => import('./pages/JuegosInfantiles/FutbolF.js'))} />
						<Route exact path="/juegos-de-futbol/:id" component={lazy(() => import('./pages/GamePage/GamePageF.js'))} />
						<Route exact path="/rompecabezas-para-niños" component={lazy(() => import('./pages/JuegosInfantiles/PuzzlesF.js'))} /> 
						<Route exact path="/rompecabezas-para-niños/:id" component={lazy(() => import('./pages/GamePage/GamePageF.js'))} /> 
						<Route exact path="/juegos-de-navidad" component={lazy(() => import('./pages/JuegosInfantiles/NavidadF.js'))} /> 
						<Route exact path="/juegos-de-navidad/:id" component={lazy(() => import('./pages/GamePage/GamePageF.js'))} /> 
						<Route exact path="/juegos-del-abecedario" component={lazy(() => import('./pages/Language/AlphabetGamesF.js'))} /> 
						<Route exact path="/juegos-del-abecedario/:id" component={lazy(() => import('./pages/GamePage/GamePageF.js'))} />  
						<Route exact path="/juegos-de-lenguaje" component={lazy(() => import('./pages/Language/Lenguaje.js'))} /> 
						<Route exact path="/juegos-de-lenguaje/:id" component={lazy(() => import('./pages/GamePage/GamePageF.js'))} />  
						<Route exact path="/prelectores/:id" component={lazy(() => import('./pages/GamePage/AprenderGamePageF.js'))} />
						<Route exact path="/lectores-emergentes/:id" component={lazy(() => import('./pages/GamePage/AprenderGamePageF.js'))} />
						<Route exact path="/juegos-de-vocales" component={lazy(() => import('./pages/Language/VowelGamesF.js'))} /> 
						<Route exact path="/juegos-de-vocales/:id" component={lazy(() => import('./pages/GamePage/GamePageF.js'))} /> 
						<Route exact path="/juegos-para-ninos-6-y-7-anos" component={lazy(() => import('./pages/Levels/CuriososF.js'))} /> 
						<Route exact path="/juegos-para-ninos-de-preescolar" component={lazy(() => import('./pages/Levels/ExploradoresF.js'))} /> 
						<Route exact path="/lecturas-cortas-para-niños" component={lazy(() => import('./pages/Levels/IngeniososF.js'))} /> 
						<Route exact path="/juegos-de-sumas" component={lazy(() => import('./pages/Math/AdditionGamesFF.js'))} /> 
						<Route exact path="/juegos-de-sumas/:id" component={lazy(() => import('./pages/GamePage/GamePageF.js'))} /> 
						<Route exact path="/juegos-de-matematicas" component={lazy(() => import('./pages/Math/MathGamesLandingF.js'))} /> 
						<Route exact path="/divisiones" component={lazy(() => import('./pages/Math/DivisionF.js'))} />
						<Route exact path="/divisiones/:id" component={lazy(() => import('./pages/GamePage/GamePageF.js'))} /> 
						<Route exact path="/fracciones/:id" component={lazy(() => import('./pages/GamePage/GamePageF.js'))} />
						<Route exact path="/numeros-decimales/:id" component={lazy(() => import('./pages/GamePage/GamePageF.js'))} />
						<Route exact path="/numeros-romanos" component={lazy(() => import('./pages/Math/RomanNumerals.js'))} />
						<Route exact path="/juegos-tablas-de-multiplicar" component={lazy(() => import('./pages/Math/Multiplication/LandingF.js'))} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-1" component={lazy(() => import('./pages/Math/Multiplication/Tabla1F.js'))} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-1/:id" component={lazy(() => import('./pages/GamePage/SubFolderGamePageF.js'))} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-2" component={lazy(() => import('./pages/Math/Multiplication/Tabla2F.js'))} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-2/:id" component={lazy(() => import('./pages/GamePage/SubFolderGamePageF.js'))} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-3" component={lazy(() => import('./pages/Math/Multiplication/Tabla3F.js'))} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-3/:id" component={lazy(() => import('./pages/GamePage/SubFolderGamePageF.js'))} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-4" component={lazy(() => import('./pages/Math/Multiplication/Tabla4F.js'))} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-4/:id" component={lazy(() => import('./pages/GamePage/SubFolderGamePageF.js'))} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-5" component={lazy(() => import('./pages/Math/Multiplication/Tabla5F.js'))} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-5/:id" component={lazy(() => import('./pages/GamePage/SubFolderGamePageF.js'))} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-6" component={lazy(() => import('./pages/Math/Multiplication/Tabla6F.js'))} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-6/:id" component={lazy(() => import('./pages/GamePage/SubFolderGamePageF.js'))} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-7" component={lazy(() => import('./pages/Math/Multiplication/Tabla7F.js'))} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-7/:id" component={lazy(() => import('./pages/GamePage/SubFolderGamePageF.js'))} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-8" component={lazy(() => import('./pages/Math/Multiplication/Tabla8F.js'))} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-8/:id" component={lazy(() => import('./pages/GamePage/SubFolderGamePageF.js'))} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-9" component={lazy(() => import('./pages/Math/Multiplication/Tabla9F.js'))} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-9/:id" component={lazy(() => import('./pages/GamePage/SubFolderGamePageF.js'))} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-10" component={lazy(() => import('./pages/Math/Multiplication/Tabla10F.js'))} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-10/:id" component={lazy(() => import('./pages/GamePage/SubFolderGamePageF.js'))} />
						<Route exact path="/juegos-tablas-de-multiplicar/mixta/:id" component={lazy(() => import('./pages/GamePage/SubFolderGamePageF.js'))} />
						<Route exact path="/juegos-tablas-de-multiplicar/tablas-imprimibles/:id" component={lazy(() => import('./pages/Math/Multiplication/PrintablesF.js'))} />
						<Route exact path="/juegos-de-numeros" component={lazy(() => import('./pages/Math/NumberGamesFF.js'))} /> 
						<Route exact path="/juegos-de-numeros/:id" component={lazy(() => import('./pages/GamePage/GamePageF.js'))} /> 
						<Route exact path="/juegos-de-figuras-geometricas/:id" component={lazy(() => import('./pages/GamePage/GamePageF.js'))} /> 
						<Route exact path="/figuras-geometricas" component={lazy(() => import('./pages/Math/FigurasGeometricasF.js'))} />
						<Route exact path="/juegos-de-restas" component={lazy(() => import('./pages/Math/SubtractionGamesF.js'))} /> 
						<Route exact path="/juegos-de-restas/:id" component={lazy(() => import('./pages/GamePage/GamePageF.js'))} /> 
						<Route exact path="/metodologia" component={lazy(() => import('./pages/Methodology/Methodology.js'))} />
						<Route exact path="/refranes" component={lazy(() => import('./pages/Refranes/RefranesF.js'))} />
						<Route exact path="/rimas-para-ninos" component={lazy(() => import('./pages/Rhymes/RhymesF.js'))} />
						<Route exact path="/adivinanzas-para-ninos" component={lazy(() => import('./pages/Riddles/RiddlesLandingF.js'))} />
						<Route exact path="/adivinanzas-de-animales" component={lazy(() => import('./pages/Riddles/AdivinanzasAnimalesF.js'))} />
						<Route exact path="/adivinanzas-de-profesiones" component={lazy(() => import('./pages/Riddles/AdivinanzasProfesionesF.js'))} />
						<Route exact path="/adivinanzas-matematicas-y-numeros" component={lazy(() => import('./pages/Riddles/AdivinanzasMathF.js'))} />
						<Route exact path="/adivinanzas-de-frutas" component={lazy(() => import('./pages/Riddles/AdivinanzasFrutasF.js'))} />
						<Route exact path="/adivinanzas-dificiles" component={lazy(() => import('./pages/Riddles/AdivinanzasDificilesF.js'))} />
						<Route exact path="/adivinanzas-en-ingles" component={lazy(() => import('./pages/Riddles/AdivinanzasInglesF.js'))} />
						<Route exact path="/adivinanzas-de-transporte" component={lazy(() => import('./pages/Riddles/AdivinanzasTransporteF.js'))} />
						<Route exact path="/chistes-cortos-para-ninos" component={lazy(() => import('./pages/Jokes/JokesLandingF.js'))} />
						<Route exact path="/chistes-de-pepito-para-ninos" component={lazy(() => import('./pages/Jokes/PepitoF.js'))} />
						<Route exact path="/chistes-de-animales-para-ninos" component={lazy(() => import('./pages/Jokes/AnimalJokesF.js'))} />
						<Route exact path="/trabalenguas-para-ninos" component={lazy(() => import('./pages/TongueTwisters/TongueTwistersLandingF.js'))} />
						<Route exact path="/trabalenguas-para-ninos/cortos" component={lazy(() => import('./pages/TongueTwisters/TrabalenguasCortosF.js'))} />
						<Route exact path="/trabalenguas-para-ninos/dificiles" component={lazy(() => import('./pages/TongueTwisters/TrabalenguasLargosF.js'))} />
						<Route exact path="/trabalenguas-para-ninos/en-ingles" component={lazy(() => import('./pages/TongueTwisters/TrabalenguasInglesF.js'))} />
						<Route exact path="/dibujos-para-colorear" component={lazy(() => import('./pages/Dibujos/DibujosLandingF.js'))} />
						<Route exact path="/dibujos-para-colorear/animales" component={lazy(() => import('./pages/Dibujos/DibujosAnimalesF.js'))} />
						<Route exact path="/dibujos-para-colorear/animales/:id" component={lazy(() => import('./pages/Dibujos/ColoringPageF.js'))} />
						<Route exact path="/dibujos-para-colorear/divertidos" component={lazy(() => import('./pages/Dibujos/DibujosDivertidosF.js'))} />
						<Route exact path="/dibujos-para-colorear/divertidos/:id" component={lazy(() => import('./pages/Dibujos/ColoringPageF.js'))} />
						<Route exact path="/juegos-de-laberintos-para-ninos" component={lazy(() => import('./pages/Dibujos/LaberintosF.js'))} />
						<Route exact path="/juegos-de-laberintos-para-ninos/:id" component={lazy(() => import('./pages/Dibujos/ColoringPage2F.js'))} />
						<Route exact path="/rompecabezas-imprimibles/:id" component={lazy(() => import('./pages/Dibujos/PrintablePuzzlesF.js'))} />
						<Route exact path="/dibujos-para-colorear/abecedario" component={lazy(() => import('./pages/Dibujos/DibujosABCF.js'))} />
						<Route exact path="/dibujos-para-colorear/abecedario/:id" component={lazy(() => import('./pages/Dibujos/ColoringPageF.js'))} />
						<Route exact path="/dibujos-para-colorear/numeros" component={lazy(() => import('./pages/Dibujos/Dibujos123F.js'))} />
						<Route exact path="/dibujos-para-colorear/numeros/:id" component={lazy(() => import('./pages/Dibujos/ColoringPageF.js'))} />
						<Route exact path="/dibujos-para-colorear/figuras-geometricas/:id" component={lazy(() => import('./pages/Dibujos/ColoringPageF.js'))} />
						<Route exact path="/dibujos-para-colorear/mandalas" component={lazy(() => import('./pages/Dibujos/MandalasF.js'))} />
						<Route exact path="/dibujos-para-colorear/mandalas/:id" component={lazy(() => import('./pages/Dibujos/ColoringPageF.js'))} />
						<Route exact path="/encuentra-las-diferencias" component={lazy(() => import('./pages/Dibujos/DiferenciasF.js'))} />
						<Route exact path="/encuentra-las-diferencias/:id" component={lazy(() => import('./pages/Dibujos/ColoringPage2F.js'))} />
						<Route exact path="/dibujos-para-dibujar" component={lazy(() => import('./pages/Dibujos/DrawF.js'))} />
						<Route exact path="/dibujos-para-dibujar/:id" component={lazy(() => import('./pages/Dibujos/ColoringPage2F.js'))} />
						<Route exact path="/dibujos-para-colorear/halloween" component={lazy(() => import('./pages/Dibujos/HalloweenF.js'))} />
						<Route exact path="/dibujos-para-colorear/halloween/:id" component={lazy(() => import('./pages/Dibujos/ColoringPageF.js'))} />
						<Route exact path="/dibujos-para-colorear/navidad" component={lazy(() => import('./pages/Dibujos/NavidadF.js'))} />
						<Route exact path="/dibujos-para-colorear/navidad/:id" component={lazy(() => import('./pages/Dibujos/ColoringPageF.js'))} />
						<Route exact path="/ciencia-para-niños" component={lazy(() => import('./pages/Science/ScienceLandingFF.js'))} />
						<Route exact path="/experimentos-caseros-para-niños" component={lazy(() => import('./pages/Science/ExperimentsLandingFF.js'))} />
						<Route exact path="/experimentos-caseros-para-niños/:id" component={lazy(() => import('./pages/Science/ExperimentsF.js'))} />
						<Route exact path="/ciencias-tecnologia" component={lazy(() => import('./pages/Science/TechnologyFF.js'))} />
						<Route exact path="/ciencias-tecnologia/articulos/:id" component={lazy(() => import('./pages/Science/ScienceArticlesF.js'))} />
						<Route exact path="/ciencias-tecnologia/libros/:id" component={lazy(() => import('./pages/SliderBook/SliderBookF.js'))} />
						<Route exact path="/ciencias-tecnologia/arbol-abc/:id" component={lazy(() => import('./pages/StoryPage/ScienceGameStoryF.js'))} />
						<Route exact path="/animales-para-ninos" component={lazy(() => import('./pages/Science/NewAnimalLandingF.js'))} />
						<Route exact path="/animales-para-ninos/mamiferos" component={lazy(() => import('./pages/Science/AnimalSubLanding/MammalsF.js'))} />
						<Route exact path="/animales-para-ninos/mamiferos/:id" component={lazy(() => import('./pages/Science/NewAnimalArticlesF.js'))} />
						<Route exact path="/animales-para-ninos/reptiles" component={lazy(() => import('./pages/Science/AnimalSubLanding/ReptilesF.js'))} />
						<Route exact path="/animales-para-ninos/reptiles/:id" component={lazy(() => import('./pages/Science/NewAnimalArticlesF.js'))} />
						<Route exact path="/animales-para-ninos/aves" component={lazy(() => import('./pages/Science/AnimalSubLanding/BirdsF.js'))} />
						<Route exact path="/animales-para-ninos/aves/:id" component={lazy(() => import('./pages/Science/NewAnimalArticlesF.js'))} />
						<Route exact path="/animales-para-ninos/anfibios" component={lazy(() => import('./pages/Science/AnimalSubLanding/AnfibiosF.js'))} />
						<Route exact path="/animales-para-ninos/anfibios/:id" component={lazy(() => import('./pages/Science/NewAnimalArticlesF.js'))} />
						<Route exact path="/animales-para-ninos/peces" component={lazy(() => import('./pages/Science/AnimalSubLanding/FishF.js'))} />
						<Route exact path="/animales-para-ninos/peces/:id" component={lazy(() => import('./pages/Science/NewAnimalArticlesF.js'))} />
						<Route exact path="/animales-para-ninos/invertebrados" component={lazy(() => import('./pages/Science/AnimalSubLanding/InvertebratesF.js'))} />
						<Route exact path="/animales-para-ninos/invertebrados/:id" component={lazy(() => import('./pages/Science/NewAnimalArticlesF.js'))} />
						<Route exact path="/animales-para-ninos/articulos/:id" component={lazy(() => import('./pages/Science/AnimalBlogArticleF.js'))} />
						<Route exact path="/juegos-de-animales" component={lazy(() => import('./pages/JuegosInfantiles/JuegosAnimalesF.js'))} />
						<Route exact path="/juegos-de-animales/:id" component={lazy(() => import('./pages/GamePage/GamePageF.js'))} />
						<Route exact path="/dibujos-animados" component={lazy(() => import('./pages/Videos/VideosLandingF.js'))} />
						<Route exact path="/dibujos-animados/om-nom" component={lazy(() => import('./pages/Videos/LongSeriesPageF.js'))} />
						<Route exact path="/dibujos-animados/om-nom/:id" component={lazy(() => import('./pages/GamePage/VideoPageF.js'))} />
						<Route exact path="/dibujos-animados/bernard" component={lazy(() => import('./pages/Videos/LongSeriesPageF.js'))} />
						<Route exact path="/dibujos-animados/bernard/:id" component={lazy(() => import('./pages/GamePage/VideoPageF.js'))} />
						<Route exact path="/dibujos-animados/qumi-qumi" component={lazy(() => import('./pages/Videos/LongSeriesPageF.js'))} />
						<Route exact path="/dibujos-animados/qumi-qumi/:id" component={lazy(() => import('./pages/GamePage/VideoPageF.js'))} />
						<Route exact path="/dibujos-animados/menuda-noche" component={lazy(() => import('./pages/Videos/SeriesPageF.js'))} />
						<Route exact path="/dibujos-animados/menuda-noche/:id" component={lazy(() => import('./pages/GamePage/VideoPageF.js'))} />
						<Route exact path="/dibujos-animados/number-farm" component={lazy(() => import('./pages/Videos/SeriesPageF.js'))} />
						<Route exact path="/dibujos-animados/number-farm/:id" component={lazy(() => import('./pages/GamePage/VideoPageF.js'))} />
						<Route exact path="/dibujos-animados/learning-zoo" component={lazy(() => import('./pages/Videos/SeriesPageF.js'))} />
						<Route exact path="/dibujos-animados/learning-zoo/:id" component={lazy(() => import('./pages/GamePage/VideoPageF.js'))} />
						<Route exact path="/dibujos-animados/rockalingua" component={lazy(() => import('./pages/Videos/SeriesPageF.js'))} />
						<Route exact path="/dibujos-animados/rockalingua/:id" component={lazy(() => import('./pages/GamePage/VideoPageF.js'))} />
						<Route exact path="/dibujos-animados/toonimals" component={lazy(() => import('./pages/Videos/LongSeriesPageF.js'))} />
						<Route exact path="/dibujos-animados/toonimals/:id" component={lazy(() => import('./pages/GamePage/VideoPageF.js'))} />
						<Route exact path="/dibujos-animados/suckers" component={lazy(() => import('./pages/Videos/LongSeriesPageF.js'))} />
						<Route exact path="/dibujos-animados/suckers/:id" component={lazy(() => import('./pages/GamePage/VideoPageF.js'))} />
						<Route exact path="/dibujos-animados/las-aventuras-minchi" component={lazy(() => import('./pages/Videos/LongSeriesPageF.js'))} />
						<Route exact path="/dibujos-animados/las-aventuras-minchi/:id" component={lazy(() => import('./pages/GamePage/VideoPageF.js'))} />
						<Route exact path="/curriculo" component={lazy(() => import('./pages/Curriculum/Curriculum.js'))} />
						<Route exact path="/mision-social" component={lazy(() => import('./pages/SocialMission/SocialMission.js'))} />
						<Route exact path="/nuestra-historia" component={lazy(() => import('./pages/Historia/Historia.js'))} />
						<Route exact path="/frases-de-educacion" component={lazy(() => import('./pages/frasesEducativas/FrasesEducativasF.js'))} />
						<Route exact path="/juegos-de-preguntas/:id" component={lazy(() => import('./pages/GamePage/GamePageF.js'))} /> 
						<Route exact path="/material-educativo" component={lazy(() => import('./pages/Recursos/recursosF.js'))} />
						<Route exact path="/material-educativo/:id" component={lazy(() => import('./pages/Recursos/RecursoArticleF.js'))} />
						<Route exact path="/recursos/:id" component={lazy(() => import('./pages/Recursos/BlogArticleF.js'))} />
						<ProtectedRoute exact path="/plus/preguntas-frecuentes" component={FAQS} />
						<ProtectedRoute exact path="/plus/faq" component={FAQEngS} />
						<ProtectedRoute exact path="/plus/politica-de-privacidad" component={PrivacyPolicyS} />
						<ProtectedRoute exact path="/plus/privacy-policy" component={PrivacyPolicyEngS} />
						<ProtectedRoute exact path="/plus/terminos-y-condiciones" component={TermsPageS} />
						<ProtectedRoute exact path="/plus/terms-of-use" component={TermsPageEngS} />
						<ProtectedRoute exact path="/plus/terminos-de-pago" component={PaymentTermsS} />
						<ProtectedRoute exact path="/plus/payment-terms" component={PaymentTermsEngS} />
						<ProtectedRoute exact path="/plus/canciones-infantiles" component={CancionesS} />
						<ProtectedRoute exact path="/plus/canciones-infantiles/:id" component={SongPageS} />
						<ProtectedRoute exact path="/plus/letras-imprimibles/:id" component={LyricPageS} />
						<ProtectedRoute exact path="/plus/cuentos-para-dormir" component={BedtimeS} />
						<ProtectedRoute exact path="/plus/cuentos-para-dormir/:id" component={StoryPageS} />
						<ProtectedRoute exact path="/plus/cuentos-para-dormir/cuentos-ilustrados/:id" component={SliderBookS} />
						<ProtectedRoute exact path="/plus/cuentos-clasicos-infantiles" component={CuentosClasicosS} />
						<ProtectedRoute exact path="/plus/cuentos-clasicos-infantiles/:id" component={StoryPageS} />
						<ProtectedRoute exact path="/plus/cuentos-clasicos-infantiles/arbol-abc/:id" component={StoryGamePageS} />
						<ProtectedRoute exact path="/plus/cuentos-en-ingles" component={CuentosInglesS} />
						<ProtectedRoute exact path="/plus/cuentos-en-ingles/clasicos/:id" component={ShortStoryPageS} />
						<ProtectedRoute exact path="/plus/cuentos-en-ingles/cuentos-ilustrados/:id" component={SliderBookS} />
						<ProtectedRoute exact path="/plus/cuentos-del-mundo" component={CuentosMundoS} />
						<ProtectedRoute exact path="/plus/cuentos-del-mundo/:id" component={StoryPageS} />
						<ProtectedRoute exact path="/plus/cuentos-de-princesas" component={CuentosPrincesasS} />
						<ProtectedRoute exact path="/plus/cuentos-de-princesas/:id" component={StoryPageS} />
						<ProtectedRoute exact path="/plus/fabulas-para-niños" component={FablesS} />
						<ProtectedRoute exact path="/plus/fabulas-para-niños/:id" component={ShortStoryPageS} />
						<ProtectedRoute exact path="/plus/cuentos-hermanos-grimm" component={GrimmS} />
						<ProtectedRoute exact path="/plus/cuentos-hermanos-grimm/:id" component={StoryPageS} />
						<ProtectedRoute exact path="/plus/mitos-y-leyendas-para-niños" component={LegendsS} />
						<ProtectedRoute exact path="/plus/mitos-y-leyendas-para-niños/:id" component={ShortStoryPageS} />
						<ProtectedRoute exact path="/plus/cuentos-inventados" component={OriginalStoriesS} />
						<ProtectedRoute exact path="/plus/cuentos-inventados/:id" component={StoryPageS} />
						<ProtectedRoute exact path="/plus/cuentos-inventados/cuentos-ilustrados/:id" component={SliderBookS} />
						<ProtectedRoute exact path="/plus/cuentos-inventados/arbol-abc/:id" component={StoryGamePageS} />
						<ProtectedRoute exact path="/plus/poemas-para-niños" component={PoemsS} />
						<ProtectedRoute exact path="/plus/poemas-para-niños/:id" component={PoemPageS} />
						<ProtectedRoute exact path="/plus/cuentos-de-terror-cortos" component={TerrorS} />
						<ProtectedRoute exact path="/plus/cuentos-de-animales" component={CuentosAnimalesS} />
						<ProtectedRoute exact path="/plus/cuentos-latinoamericanos" component={CuentosLatinamericanosS} />
						<ProtectedRoute exact path="/plus/cuentos-latinoamericanos/:id" component={StoryPageS} />
						<ProtectedRoute exact path="/plus/cuentos-de-terror-cortos/:id" component={ShortStoryPageS} />
						<ProtectedRoute exact path="/plus/cuentos-infantiles-cortos" component={CuentosLandingS} />
						<ProtectedRoute exact path="/plus/curriculo" component={CurriculumS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/numeros" component={Dibujos123S} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/numeros/:id" component={ColoringPageS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/abecedario" component={DibujosABCS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/abecedario/:id" component={ColoringPageS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/animales" component={DibujosAnimalesS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/animales/:id" component={ColoringPageS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/divertidos" component={DibujosDivertidosS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/divertidos/:id" component={ColoringPageS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear" component={DibujosLandingS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/figuras-geometricas/:id" component={ColoringPageS} />
						<ProtectedRoute exact path="/plus/encuentra-las-diferencias" component={DiferenciasS} />
						<ProtectedRoute exact path="/plus/encuentra-las-diferencias/:id" component={ColoringPage2S} />
						<ProtectedRoute exact path="/plus/dibujos-para-dibujar" component={DrawS} />
						<ProtectedRoute exact path="/plus/dibujos-para-dibujar/:id" component={ColoringPage2S} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/halloween" component={HalloweenS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/halloween/:id" component={ColoringPageS} />
						<ProtectedRoute exact path="/plus/juegos-de-laberintos-para-ninos" component={LaberintosS} />
						<ProtectedRoute exact path="/plus/juegos-de-laberintos-para-ninos/:id" component={ColoringPage2S} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/mandalas" component={MandalasS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/mandalas/:id" component={ColoringPageS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/navidad" component={NavidadS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/navidad/:id" component={ColoringPageS} />
						<ProtectedRoute exact path="/plus/rompecabezas-imprimibles/:id" component={PrintablePuzzlesS} />
						<ProtectedRoute exact path="/plus/abecedario-en-ingles" component={AlphabetEnglishS} />
						<ProtectedRoute exact path="/plus/abecedario-en-ingles/:id" component={GamePageS} />
						<ProtectedRoute exact path="/plus/colores-en-ingles" component={ColorsEnglishS} />
						<ProtectedRoute exact path="/plus/colores-en-ingles/:id" component={GamePageS} />
						<ProtectedRoute exact path="/plus/juegos-de-ingles" component={EnglishGamesS} />
						<ProtectedRoute exact path="/plus/vocabulario-en-ingles" component={FeelingsEnglishS} />
						<ProtectedRoute exact path="/plus/vocabulario-en-ingles/:id" component={GamePageS} />
						<ProtectedRoute exact path="/plus/numeros-en-ingles" component={NumbersEnglishS} />
						<ProtectedRoute exact path="/plus/numeros-en-ingles/:id" component={GamePageS} />
						<ProtectedRoute exact path="/plus/frases-de-educacion" component={FrasesEducativasS} />
						<ProtectedRoute exact path="/plus/nuestra-historia" component={HistoriaS} />
						<ProtectedRoute exact path="/plus" component={HomeS} />
						<ProtectedRoute exact path="/plus/chistes-de-animales-para-ninos" component={AnimalJokesS} />
						<ProtectedRoute exact path="/plus/chistes-cortos-para-ninos" component={JokesLandingS} />
						<ProtectedRoute exact path="/plus/chistes-de-pepito-para-ninos" component={PepitoS} />
						<ProtectedRoute exact path="/plus/juegos-de-colores" component={ColorGamesS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-colores/:id" component={GamePageS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-futbol" component={FutbolS} />
						<ProtectedRoute exact path="/plus/juegos-de-futbol/:id" component={GamePageS} />
						<ProtectedRoute exact path="/plus/juegos-infantiles" component={JuegosInfantilesS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-memoria" component={MemoryGamesS} />
						<ProtectedRoute exact path="/plus/juegos-de-memoria/:id" component={GamePageS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-estrategia" component={MentalGamesS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-estrategia/:id" component={GamePageS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-navidad" component={NavidadGamesS} />
						<ProtectedRoute exact path="/plus/juegos-de-navidad/:id" component={GamePageS} />
						<ProtectedRoute exact path="/plus/rompecabezas-para-niños" component={PuzzlesS} /> 
						<ProtectedRoute exact path="/plus/rompecabezas-para-niños/:id" component={GamePageS} /> 
						<ProtectedRoute exact path="/plus/juegos-del-abecedario" component={AlphabetGamesS} /> 
						<ProtectedRoute exact path="/plus/juegos-del-abecedario/:id" component={GamePageS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-lenguaje" component={LenguajeS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-lenguaje/:id" component={GamePageS} /> 
						<ProtectedRoute exact path="/plus/game-test/:id" component={GamePageS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-vocales" component={VowelGamesS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-vocales/:id" component={GamePageS} /> 
						<ProtectedRoute exact path="/plus/prelectores/:id" component={AprenderGamePageS} />
						<ProtectedRoute exact path="/plus/lectores-emergentes/:id" component={AprenderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-para-ninos-6-y-7-anos" component={CuriososS} /> 
						<ProtectedRoute exact path="/plus/juegos-para-ninos-de-preescolar" component={ExploradoresS} /> 
						<ProtectedRoute exact path="/plus/lecturas-cortas-para-niños" component={IngeniososS} /> 
						<ProtectedRoute exact path="/plus/enlazanos" component={LinkPageS} />
						<ProtectedRoute exact path="/plus/divisiones" component={DivisionS} />
						<ProtectedRoute exact path="/plus/divisiones/:id" component={GamePageS} />
						<ProtectedRoute exact path="/plus/fracciones/:id" component={GamePageS} />  
						<ProtectedRoute exact path="/plus/numeros-decimales/:id" component={GamePageS} />
						<ProtectedRoute exact path="/plus/numeros-romanos" component={NumerosRomanosS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar" component={MultiplicationS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tablas-imprimibles/:id" component={MultiplicationPrintablesS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-1" component={Tabla1S} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-1/:id" component={SubFolderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-2" component={Tabla2S} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-2/:id" component={SubFolderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-3" component={Tabla3S} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-3/:id" component={SubFolderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-4" component={Tabla4S} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-4/:id" component={SubFolderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-5" component={Tabla5S} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-5/:id" component={SubFolderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-6" component={Tabla6S} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-6/:id" component={SubFolderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-7" component={Tabla7S} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-7/:id" component={SubFolderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-8" component={Tabla8S} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-8/:id" component={SubFolderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-9" component={Tabla9S} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-9/:id" component={SubFolderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-10" component={Tabla10S} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-10/:id" component={SubFolderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/mixta/:id" component={SubFolderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-de-sumas" component={AdditionGamesS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-sumas/:id" component={GamePageS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-matematicas" component={MathGamesLandingS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-numeros" component={NumberGamesS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-numeros/:id" component={GamePageS} />
						<ProtectedRoute exact path="/plus/figuras-geometricas" component={FigurasGeometricasS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-figuras-geometricas/:id" component={GamePageS} />
						<ProtectedRoute exact path="/plus/juegos-de-restas" component={SubtractionGamesS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-restas/:id" component={GamePageS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-preguntas/:id" component={GamePageS} /> 
						<ProtectedRoute exact path="/plus/metodologia" component={MethodologyS} />
						<ProtectedRoute exact path="/plus/material-educativo" component={RecursosS} />
						<ProtectedRoute exact path="/plus/material-educativo/:id" component={RecursosArticlesS} />
						<ProtectedRoute exact path="/plus/adivinanzas-de-animales" component={AdivinanzasAnimalesS} />
						<ProtectedRoute exact path="/plus/adivinanzas-dificiles" component={AdivinanzasDificilesS} />
						<ProtectedRoute exact path="/plus/adivinanzas-de-frutas" component={AdivinanzasFrutasS} />
						<ProtectedRoute exact path="/plus/adivinanzas-en-ingles" component={AdivinanzasInglesS} />
						<ProtectedRoute exact path="/plus/adivinanzas-matematicas-y-numeros" component={AdivinanzasMathS} />
						<ProtectedRoute exact path="/plus/adivinanzas-de-profesiones" component={AdivinanzasProfesionesS} />
						<ProtectedRoute exact path="/plus/adivinanzas-de-transporte" component={AdivinanzasTransporteS} />
						<ProtectedRoute exact path="/plus/adivinanzas-para-ninos" component={RiddlesLandingS} />
						<ProtectedRoute exact path="/plus/refranes" component={RefranesS} />
						<ProtectedRoute exact path="/plus/rimas-para-ninos" component={RhymesS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos" component={NewAnimalLandingS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/articulos/:id" component={AnimalBlogArticleS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/mamiferos" component={MamiferosS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/mamiferos/:id" component={NewAnimalArticlesS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/reptiles" component={ReptilesS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/reptiles/:id" component={NewAnimalArticlesS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/aves" component={BirdsS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/aves/:id" component={NewAnimalArticlesS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/anfibios" component={AnfibiosS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/anfibios/:id" component={NewAnimalArticlesS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/peces" component={FishS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/peces/:id" component={NewAnimalArticlesS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/invertebrados" component={InvertebratesS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/invertebrados/:id" component={NewAnimalArticlesS} />
						<ProtectedRoute exact path="/plus/juegos-de-animales" component={JuegosAnimalesS} />
						<ProtectedRoute exact path="/plus/juegos-de-animales/:id" component={GamePageS} />
						<ProtectedRoute exact path="/plus/experimentos-caseros-para-niños" component={ExperimentsLandingS} />
						<ProtectedRoute exact path="/plus/experimentos-caseros-para-niños/:id" component={ExperimentsS} />
						<ProtectedRoute exact path="/plus/ciencia-para-niños" component={ScienceLandingS} />
						<ProtectedRoute exact path="/plus/ciencias-tecnologia" component={TechnologyS} />
						<ProtectedRoute exact path="/plus/ciencias-tecnologia/articulos/:id" component={ScienceArticlesS} />
						<ProtectedRoute exact path="/plus/ciencias-tecnologia/libros/:id" component={SliderBookS} />
						<ProtectedRoute exact path="/plus/ciencias-tecnologia/arbol-abc/:id" component={ScienceGameStoryS} />
						<ProtectedRoute exact path="/plus/mision-social" component={SocialMissionS} />
						<ProtectedRoute exact path="/plus/trabalenguas-para-ninos" component={TongueTwistersLandingS} />
						<ProtectedRoute exact path="/plus/trabalenguas-para-ninos/cortos" component={TrabalenguasCortosS} />
						<ProtectedRoute exact path="/plus/trabalenguas-para-ninos/en-ingles" component={TrabalenguasInglesS} />
						<ProtectedRoute exact path="/plus/trabalenguas-para-ninos/dificiles" component={TrabalenguasLargosS} />
						<ProtectedRoute exact path="/plus/como-agregar-favoritos" component={FavoritesInstructionsS} />
						<ProtectedRoute exact path="/plus/favoritos" component={Favoritos} />
						<ProtectedRoute exact path="/plus/recursos/:id" component={BlogArticleS} />
						<Redirect from="/index.html" to="/" />
						<Redirect from="/juegos-de-figuras-geometricas" to="/figuras-geometricas" />
						<Redirect from="/plus/juegos-de-figuras-geometricas" to="/plus/figuras-geometricas" />
						<Redirect from="/dibujos-para-colorear/figuras-geometricas" to="/figuras-geometricas" />
						<Redirect from="/plus/dibujos-para-colorear/figuras-geometricas" to="/plus/figuras-geometricas" />
						<Redirect from="/juegos-tablas-de-multiplicar/mixta" to="/juegos-tablas-de-multiplicar" />
						<Redirect from="/plus/juegos-tablas-de-multiplicar/mixta" to="/plus/juegos-tablas-de-multiplicar" />
						<Redirect from="/fracciones" to="/juegos-de-matematicas" />
						<Redirect from="/plus/fracciones" to="/plus/juegos-de-matematicas" />
						<Redirect from="/numeros-decimales" to="/juegos-de-matematicas" />
						<Redirect from="/plus/numeros-decimales" to="/plus/juegos-de-matematicas" />
						<Route component={NotFound} />
					</Switch>
    			</Suspense>
  			</Router>
		)
	}
}

export default App